#wrapper {
  position: absolute;

  width: 100vw; height: 100vh;

  display: flex;
  justify-content: space-evenly;
  /* justify-content: center; */
  align-items: center;
}

#backdropFull {
  width: 100%; height: 100%;
  position: absolute;
  z-index: -1;

  /* background: url(./ModeList/noise.png) #4e67cd; */
  /* background: url(./ModeList/noise.png) #7F7F7F; */
  background: url(./components/ModeList/noise.png) #333;
  /* background: url(bg2.jpeg) center/200%;
  filter: saturate(0) contrast(22%) brightness(50%); */
}

#backdropA {
  width: 50%; height: 100%;
  position: absolute;
  left: 0;
  z-index: -1;

  background: url(./components/ModeList/noise.png) #333;
  /* background: url(./ModeList/noise.png) #e56f5a; */
}

#backdropB {
  width: 50%; height: 100%;
  position: absolute;
  right: 0;
  z-index: -1;

  /* background: url(bg2.jpeg) center/300%; */
  background: url(./components/ModeList/noise.png) #4e67cd;
  /* background: url(./ModeList/noise.png) #ddd; */
}