.cardContent {
  height: 35px;
  /* position: relative; */

  outline: 1px solid #00000088;
  border-radius: 4px;

  box-shadow: -2px 2px 3px #00000055, inset -1px 1px 2px #ffffff99;

  cursor: grab;
  user-select: none;

  overflow: hidden;

  /* background: linear-gradient(180deg, transparent 33%, #ffffff44 66%); */
}

/* .bar > div:nth-child(3) > .cardContent {
  width: 50px; 
  border: 1px solid black;
  outline: 3px solid cyan;
} */

/* .bar > div > .cardContent:hover {
  width: 50px;
  transition:
    width 100ms ease-in;
} */

.cardNumber {
  color: #000;

  padding-top: 3px;

  font-family: 'Trebuchet MS';
  font-weight: bold;
  font-size: .5em;
}

.textContainer {
  white-space: nowrap;
  height: 19px;

  margin: 1px 3px 0 3px;

  border-radius: 8px;
}

.rootText {
  width: min-content;
  line-height: 18px;
  
  /* font-family: 'Zilla Slab'; */
  font-family: 'Bravura';
  font-size: .9em;
}

.seventhText {
  width: min-content;
  margin-left: 2px;
  line-height: 22px;
  
  font-family: 'Bravura';
  font-size: .6em;
}

/* .unmarked { background-color: hsl(0, 0%, 40%) } */
.unmarked { background-color: hsl(60, 100%, 50%) }
.dim      { background-color: hsl(195, 70%, 44%) }
.min      { background-color: hsl(195, 70%, 72%) }
.maj      { background-color: hsl(40,  5%,  82%) }
.aug      { background-color: hsl(15,  70%, 64%) }
.dim7     { background-color: hsl(195, 70%, 44%) }
.hdim7    { background-color: hsl(195, 70%, 44%) }
.min7     { background-color: hsl(195, 70%, 72%) }
.minmaj7  { background-color: hsl(195, 70%, 72%) }
.dom7     { background-color: hsl(40,  5%,  82%) }
.maj7     { background-color: hsl(40,  5%,  82%) }
.aug7     { background-color: hsl(15,  70%, 64%) }
.augmaj7  { background-color: hsl(15,  70%, 64%) } 


/* 
.augmaj7 { background-color: hsl(0,   60%, 70%) }
.aug7    { background-color: hsl(45,  60%, 70%) }
.maj7    { background-color: hsl(90,  60%, 70%) }
.dom7    { background-color: hsl(135, 60%, 70%) }
.minmaj7 { background-color: hsl(180, 60%, 70%) }
.min7    { background-color: hsl(225, 60%, 70%) }
.hdim7   { background-color: hsl(270, 60%, 70%) }
.dim7    { background-color: hsl(315, 60%, 70%) }  
*/

.empty { 
  min-width: 25px; max-width: 57px; 
  width: 100%; height: 35px;
  line-height: 35px;
  border-radius: 4px;
  box-shadow: inset 1px -1px 1px #ffffff80, inset -2px 2px 6px #00000040;

  font-size: .9em;
  font-weight: bold;
  text-align: center;
  color: #ffffff;

  overflow-x: hidden;

  user-select: none;
}